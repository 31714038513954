import { jwtDecode } from 'jwt-decode';

export const getUserDetailsFromToken = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        return decodedToken; // Assuming the user details are in the token payload
    } catch (error) {
        console.error('Invalid token:', error);
        return null;
    }
};