import React, { useState, useEffect } from 'react';
import { useMenuLists } from "../hooks/userFetch";
export default function Footer() {
  const { footerSection } = useMenuLists();
  const logoUrl = footerSection.logo?.data?.attributes?.url;
  const BASE_URL = process.env.REACT_APP_CMS_API_URL;
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  return (
    <div>
      {footerSection ?
        <footer className="footer-light" style={{ background: '#F9F9F9' }}>
          <div className="container">
            <div className="row g-custom-x">
              <div className="col-lg-4" >
                {logoUrl && (
                  <img src={`${BASE_URL}${logoUrl}`} alt="Opendata meteo.be" />
                )}
              </div>
              <div className="col-lg-3" style={{ marginRight: '40px' }}>

                {/* <div className="spacer-20"></div> */}
                {footerSection.text?.map((item, index) => (
                  <p key={index}>
                    {item.children.map((child, childIndex) => (
                      <span key={childIndex}>{child.text}</span>
                    ))}
                  </p>
                ))}
                <div className="spacer-10"></div>
              </div>

              {footerSection.footerMenu?.map((menu, index) => (
                <div className="col-lg-3" style={{ marginRight: '40px' }} key={menu.id}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="widget">
                        <h5>{menu.heading}</h5>
                        <ul>
                          {menu.footer.map((item) => (
                            <li key={item.id}>
                              <a href={item.link || "#"}>{item.name}</a>
                            </li>
                     
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6">

                      {footerSection.footerSocialMedia?.map((social) => (
                        <div className="widget" key={social.id}>
                          <h5>{social.heading}</h5>
                          <div className="social-icons" style={{
                            display: 'flex',
                            width: '200px'
                          }} >
                            {social.medialist.map((media, mediaIndex) => (
                              <div key={media.id} style={{ width: '55px', position: 'relative' }}>
                                <a
                                  href={media.link}
                                  target={media.target}
                                  onClick={() => toggleDropdown(mediaIndex)}
                                >
                                  <i className={`fa fa-${media.name} fa-lg`}></i>
                                </a>
                                {openDropdown === mediaIndex && (
                                  <div style={styles.dropdownContent} >
                                    {media.language.map((lang) => (
                                      <p key={lang.id} style={{ paddingBottom: "0px" }}>
                                        <a href={lang.languageLink} target="_blank" rel="noopener noreferrer" style={{ color: "#fff" }}>{lang.languageName}</a>
                                      </p>
                                    ))}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}

                    </div>
                  </div>
                </div>
              ))}

              {/* <div className="col-lg-3 mr-5" >
              <h5>Login / Register</h5>
              </div> */}
              {/* {footerSection.footerLanguage?.map((language) => (
                <div className="col-lg-3 mr-5" key={language.id}>
                  <div className="widget">
                    <h5>{language.title}</h5>
                    <ul>
                      {language.language.map((lang) => (
                        <li key={lang.id}>
                          <a href={lang.link}>{lang.name}</a>
                        </li>
                      ))}
                    </ul>
                    <small>You can select above languages</small>
                  </div>
                </div>
              ))} */}
            </div>
          </div>

          <div className="subfooter">
            <div className="container">
              <div className="row">
                <div className="col-md-11">
                  <div className="de-flex">
                    <div className="de-flex-col">
                      {footerSection && footerSection.SubFooterSection && footerSection.SubFooterSection[0] && footerSection.SubFooterSection[0].SubFooterList && (
                        <a href="#">
                          {footerSection.SubFooterSection[0].SubFooterList[0].heading}
                        </a>
                      )}
                    </div>

                    <ul className="menu-simple">
                      {footerSection && footerSection.SubFooterSection && footerSection.SubFooterSection[0] && footerSection.SubFooterSection[0].SubFooterList &&
                        footerSection.SubFooterSection[0].SubFooterList.slice(1).map(item => (
                          <li key={item.id}>
                            <a href={item.link}>{item.heading}</a>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        : ''}
    </div>
  )
}
const styles = {

  dropdownWrapper: {
    position: 'relative',
    display: 'inline-block',
    marginRight: '10px', // Adjust as needed
  },
  dropdownToggle: {
    textDecoration: 'none',
    color: 'inherit', /* Inherits parent color */
    cursor: 'pointer',
  },
  dropdownContent: {
    top: '100%',
    left: 0,
    backgroundColor: '#204E96', /* Background color for the dropdown */
    padding: '2px 0px 0px 5px',
    borderRadius: '4px',
    width: '65px'
  },
  dropdownWrapperHover: {
    display: 'block',
  },
  dropdownContentP: {
    margin: '5px 0',
  },
  dropdownContentA: {
    color: '#fff',
    textDecoration: 'none',
  },
  dropdownContentAHover: {
    textDecoration: 'underline',
  },
};



