import React from 'react'
import { useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import subheaderImage from '../assets/images/background/subheader.jpg';
import { useMenuLists } from "../hooks/userFetch";
export default function InnerPages() {
    const location = useLocation();
    const pathnameWithoutSlash = location.pathname.replace(/^\/+/, '');
    const { menuLists } = useMenuLists();
    const mainMenuItems = menuLists?.attributes?.MainMenuItems;
    if (!mainMenuItems) {
        return null;
    }

    let currentHeading = '';
    let currentContent = '';

    mainMenuItems?.forEach(item => {
        if (item.__component === 'menu.dropdown') {
            item.sub_menus.data[0].attributes.link.forEach(link => {
                if (pathnameWithoutSlash === link.pageUrl) {
                    currentHeading = link.pageheading;
                    currentContent = link.pageContent;
                }
            });
        } else if (item.__component === 'menu.link') {
            
            if (pathnameWithoutSlash === item.url) {
                currentHeading = item.heading;
                currentContent = item.content;
                console.log(currentContent)
            }
        }
    });


    return (
        <div >
            <section id="subheader" className="jarallax" style={{ backgroundImage: `url(${subheaderImage})` }}>
                <div className="center-y relative text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h1>{currentHeading}</h1>

                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section id="section-hero" aria-label="section" style={{ padding: '60px 0 150px 0px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div >
                                <ReactMarkdown>{currentContent}</ReactMarkdown>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
