import React from 'react'
import { useMenuLists } from "../hooks/userFetch";
import home_video from '../assets/video/local-video-2.mp4';

export default function Slider() {
    const { heroContent } = useMenuLists();
    const BASE_URL = process.env.REACT_APP_CMS_API_URL;
    // const BASE_URL = `${apiUrl}:1337`;
    
    return (
        <div>
            <section className="no-top no-bottom jarallax position-relative full-height" aria-label="section"
                data-video-src={home_video}>
                <div className="overlay-bg no-top no-bottom light-7">
                    <div className="v-center">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    {heroContent?.title?.map((child) => (
                                        child.children.map((link) => (
                                            <h1 style={{ color: '#23539A' }}>
                                                {link.text}
                                            </h1>
                                        ))
                                    ))}

                                    {heroContent?.subtitle?.map((child) => (
                                        child.children.map((link) => (
                                            <h1 style={{ color: '#23539A' }}>
                                                <p className="lead">{link.text}</p>
                                            </h1>
                                        ))
                                    ))}
                                </div>
                                {heroContent?.links?.data?.attributes?.url ?
                                    <div className="col-md-6 xs-hide">

                                        <img
                                            className=" img-fluid anim-up-down"
                                            src={`${BASE_URL}${heroContent?.links?.data?.attributes?.url}`}
                                            alt={heroContent?.links?.data?.attributes?.name}
                                            style={{ width: '100%', height: '700px', marginTop: '50px' }}
                                        />
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
