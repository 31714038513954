import React, { useState, useEffect, useRef } from 'react';
import '../assets/css/mdb.min.css';  // eslint-disable-next-line 
import '../assets/css/plugins.css';
import '../assets/css/style.css';
import '../assets/css/coloring.css';
import '../assets/css/colors/scheme-01.css';
import '../assets/fonts/font-awesome/css/font-awesome.css';
import { useMenuLists } from "../hooks/userFetch";
import MenuSection from "../components/MainMenu";
import { useData } from '../context/DataContext';
import { useNavigate } from 'react-router-dom';
// import './Header.css';

export default function Header() {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { menuLists, footerSection } = useMenuLists();
    const logoUrl = footerSection.logo?.data?.attributes?.url;

    const BASE_URL = process.env.REACT_APP_CMS_API_URL;
    // const BASE_URL = `${apiUrl}:1337`;

    const { sharedData } = useData();
    const [isOpen, setIsOpen] = useState(false);
    const [hoverIndex, setHoverIndex] = useState(null);
    const dropdownRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState(null);

    // const options = ['Option 1', 'Option 2', 'Option 3'];
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 992);

    useEffect(() => {
        // Check if user is logged in based on token presence
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };


    }, [sharedData]);

    const handleProfile = (index) => {
        if (index === 0) {
            navigate('/dashboard');
        } else {
            handleLogout();
        }
    };

    const handleLogout = () => {
        localStorage.clear();
    }

    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    const handleItemMouseEnter = (index) => {
        setHoverIndex(index);

    };

    const handleItemMouseLeave = () => {
        setHoverIndex(null);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };


    const generateMenuItems = () => {
        if (isLoggedIn) {
            return [
                { href: "/dashboard", label: "View Profile" },
                { href: "/login", label: "Logout" },
            ];
        }
        return [];
    };

    const menuItems = generateMenuItems();
    const handleResize = () => {
        setIsMobileView(window.innerWidth <= 992);
    };


    const toggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    };


    return (
        <div >

            <header className="header-light transparent has-topbar">
                {/* <div id="topbar">
                    <div className="container">
                        <div className="topbar-left xs-hide">
                            <span className="topbar-widget">
                                <a href="#">Welcome to OpenData Meteo</a>
                            </span>
                        </div>
                        <div className="topbar-right">
                           
                            <div className="topbar-widget"><a href="#" style={{ marginRight: '-19px', marginTop: '3px' }}>Language</a> </div>
                            <div className="topbar-widget"
                                style={{ backgroundSize: '100%', backgroundRepeat: 'no-repeat', marginRight: '82px' }}>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div> */}
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="de-flex sm-pt10">
                                <div className="de-flex-col">
                                    <div className="de-flex-col">
                                        <div id="logo">
                                            <a href="/home">
                                                {logoUrl && (
                                                    <>
                                                        <img className="logo-main" src={`${BASE_URL}${logoUrl}`} alt="Opendata meteo.be" style={{ height: '70px', border: 'solid thick #fff' }} />
                                                        <img className="logo-mobile" src={`${BASE_URL}${logoUrl}`} alt="Mobile Logo" style={{ height: '70px', border: 'solid thick #fff' }} />
                                                    </>
                                                )}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                              
                                {(!isMobileView || isMenuVisible) && (
                                    <div className="de-flex-col header-col-mid" >
                                        <MenuSection menuLists={menuLists} />
                                    </div>
                                )}
                                <div className="de-flex-col">
                                    <div style={styles.menuSideArea} ref={dropdownRef} onMouseLeave={handleMouseLeave}>
                                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                            <li
                                                style={styles.dropdown}
                                                onMouseEnter={handleMouseEnter}
                                            >
                                                <a href="/login" style={styles.dropdownToggle}>

                                                    {isLoggedIn ? (
                                                        <>
                                                            Profile <i className="fa fa-fw" aria-hidden="true" title="Copy to use user-circle-o"></i>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <i className="fa fa-fw" aria-hidden="true" title="Copy to use lock"></i> Login / Register
                                                        </>
                                                    )} {/* Toggle text based on isLoggedIn */}
                                                </a>
                                                <ul style={{ ...styles.dropdownMenu, ...(isOpen ? styles.show : {}) }}>
                                                    {menuItems.map((item, index) => (
                                                        <li
                                                            key={index}
                                                            onMouseEnter={() => handleItemMouseEnter(index)}
                                                            onMouseLeave={handleItemMouseLeave}
                                                            style={{ listStyleType: 'none' }} // Removes the bullets from each dropdown item
                                                        >
                                                            <a
                                                                onClick={() => handleProfile(index)}
                                                                href={item.href}
                                                                style={{
                                                                    ...styles.dropdownMenuItem,
                                                                    ...(hoverIndex === index ? styles.dropdownMenuItemHover : {}),
                                                                }}
                                                            >
                                                                {item.label}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        </ul>
                                        {/* <span id="menu-btn"></span> */}
                                    </div>

                                </div>

                                <div class="de-flex-col header-col-mid" onClick={toggleMenu} >
                                    <div class="menu_side_area">

                                        <span id="menu-btn" ></span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </header>



        </div >
    )
}
const styles = {
    menuSideArea: {
        position: 'relative',
    },
    dropdown: {
        position: 'relative',
        display: 'inline-block',
        listStyleType: 'none',
    },
    dropdownToggle: {
        cursor: 'pointer',
        textDecoration: 'none',
        backgroundColor: '#204E96', // Change to your desired background color
        color: 'white',
        padding: '5px 20px',
        borderRadius: '25px', // Makes the background rounded
        display: 'inline-block',
        fontSize: '14px'
    },
    dropdownMenu: {
        display: 'none', // Initially hidden
        position: 'absolute',
        backgroundColor: 'white',
        minWidth: '160px',
        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
        zIndex: 1,
        listStyleType: 'none', // Removes the bullets from the dropdown menu items
        padding: 0, // Removes default padding
        margin: 0, // Removes default margin
        fontSize: '14px'
    },
    dropdownMenuItem: {
        color: 'black',
        padding: '12px 16px',
        textDecoration: 'none',
        display: 'block',
    },
    dropdownMenuItemHover: {
        backgroundColor: '#6bcbf4',
    },
    show: {
        display: 'block', // To show the menu
    },
};








