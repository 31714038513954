
import React from 'react';
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
  useNavigate,
} from "react-router-dom";
import { DataProvider } from './context/DataContext'; // Adjust the path
import Home from "./pages/Home";
import './App.css';
import Download from "./pages/Download";
import InnerPages from "./pages/InnerPages";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Register from "./pages/Register";
import UserProfile from "./pages/UserProfile";
import ProtectedRoute from './pages/ProtectedRoute';
import EmailVerification from './pages/EmailVerification';
import Termsandconditions from './pages/Termsandconditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
function App() {

  return (

    <div className="App">
      <div className="app-container">
        <DataProvider>
          {/* <Router> */}
          <BrowserRouter>
            <Header />
            <div className="no-bottom no-top" id="content">
              <div id="top"></div>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/download" element={<Download />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/termsandconditions" element={<Termsandconditions />} />
                <Route path='/privacyPolicy' element={<PrivacyPolicy/>} />
                <Route path="/dashboard" element={<UserProfile />} />
                {/* <Route path="/api/v1/email-confirmation" element={<EmailVerification />} /> */}
                <Route path="/:url" element={<InnerPages />} />
              </Routes>
            </div>
            <Footer />
          </BrowserRouter>
          {/* </Router> */}
        </DataProvider>
      </div>
    </div>

  );
}

export default App;
