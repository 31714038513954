import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import bgImage from '../assets/images/background/6.jpg';
import axios from 'axios';
import './Login.css'
import { useData } from '../context/DataContext';

export default function Login() {
    // const apiUrl = process.env.REACT_APP_API_URL;
    // const BASE_URL = `${apiUrl}:1337`;


    const { sharedData, setSharedData } = useData();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validate = () => {
        let formErrors = {};
        if (!formData.email) {
            formErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = 'Email is invalid';
        }
        if (!formData.password) {
            formErrors.password = 'Password is required';
        } else if (formData.password.length < 8) {
            formErrors.password = 'Password must be at least 8 characters';
        }
        return formErrors;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setErrors({});
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        // Add other headers as needed
                    },
                };
                const BASE_URL = process.env.REACT_APP_PROFILE_API_URL;
                // const BASE_URL = `${apiUrl}:4000`;
                const response = await axios.post(`${BASE_URL}/api/v1/login`, formData, config);

                if (response.data.status === "success") {
                    setFormData({
                        email: '',
                        password: '',
                    });
                    setErrors('');
                    localStorage.setItem('token', response.data.token);
                    navigate('/dashboard');
                    setSharedData(true);
                }
                // else{
                //     setErrorMessage('Invalid username or password');
                // }

            } catch (error) {
                console.error('Error logging in:', error);
                setErrorMessage(error.response.data.message);
                // Handle error (e.g., show error message)
            }
        }
    };

    const triggerMessage = () => {
        // Clear the message after 5 seconds (5000 milliseconds)
        setTimeout(() => {
            setErrors('')
        }, 5000); // Adjust the time as per your requirement (5000 ms = 5 seconds)
    };



    return (
        <div>
            <div className="no-bottom no-top" id="content">
                <div id="top"></div>
                <section id="section-hero" aria-label="section" style={{ backgroundImage: `url(${bgImage})`, backgroundPosition: 'top' }} className="full-height no-top no-bottom">
                    <div className="v-center">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-4 offset-lg-4">
                                    <div className="padding40 rounded-3 shadow-soft text-center" style={{ backgroundColor: "#ffffff" }}>
                                        <h4> LogIn </h4>
                                        <div className="spacer-10"></div>
                                        <form className="form-border" onSubmit={handleSubmit}>
                                            <div className="field-set">
                                                <input
                                                    type="text"
                                                    name="email"
                                                    id="email"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />

                                                {errors.email && <p style={{ display: 'flex', color: 'red', fontWeight: 'bold' }}>{errors.email}</p>}
                                            </div>
                                            <div className="field-set">
                                                <input
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    className="form-control"
                                                    placeholder="Password"
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                />
                                                {errors.password && <p style={{ display: 'flex', color: 'red', fontWeight: 'bold' }}>{errors.password}</p>}
                                            </div>
                                            <div id="submit">
                                                <input
                                                    type="submit"
                                                    id="send_message"
                                                    value="LogIn"
                                                    className="btn-main btn-fullwidth rounded-3"
                                                />

                                            </div>
                                        </form>
                                        {errorMessage && (
                                            <div style={{ margin: '10px', fontSize: '14px', padding: '10px', color: '#721c24', bordercolor: '#f5c6cb', fontWeight: 'bold', backgroundColor: '#f8d7da', }}>
                                                {errorMessage}
                                            </div>
                                        )}
                                        <div className="title-line">Or&nbsp;sign&nbsp;up&nbsp;with</div>
                                        <div className="row g-2">
                                            <div className="col-lg-12">
                                                <a className="btn-sc btn-fullwidth mb10" href="/register">Register</a>
                                                <em>Only academic users are able to sign up.</em>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
