
import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Slider from '../components/Slider';
import Footer from '../components/Footer';
import { useMenuLists } from "../hooks/userFetch";
import ReactMarkdown from 'react-markdown';

export default function Home() {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   const token = localStorage.getItem('token');
  //   if (token) {
  //     navigate('/profile', { replace: true });
  //   }
  // }, [navigate]);

  const { homeFeature } = useMenuLists();

  const BASE_URL = process.env.REACT_APP_CMS_API_URL;
  // const BASE_URL = `${apiUrl}:1337`;
  return (
    <div>
      {/* slider section */}
      <Slider />

      {/* Feature section -1 */}
      {homeFeature?.sectionlevels?.map((items, index) => (
        <section key={index} style={{ padingTop: '10px', backgroundColor: items.background === 'Blue' ? '#DBEDF7' : '#FFFFFF' }} >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8 offset-lg-2 text-center">
                {/* <h5 className="s2">Download Data</h5> */}
                <h2 style={{ color: '#23539A' }}> {items.title} </h2>
                <p className="lead">{items.subtitle}</p>
              </div>
              <div className="col-lg-6">
                <img
                  className="img-fluid anim-up-down"
                  src={`${BASE_URL}${items.image.data[0].attributes.formats.medium.url}`}
                  alt={items.image.data[0].attributes.name}
                  style={{ width: "85%" }}
                />
              </div>
              <div className="col-lg-6" data-wow-delay=".3s">
                <div className="p-sm-30 pb-sm-0 mb-sm-0">
                  {items?.description?.map((desc, descIndex) => (
                    <span key={descIndex}>
                      {desc.children.map((child, childIndex) => (
                        <h2 key={childIndex} style={{ color: '#23539A' }}>{child.text}</h2>
                      ))}
                    </span>
                  ))}


                  <p>{items?.subdescription?.map((subDesc, subDescIndex) => (
                    <div key={subDescIndex}>
                      {subDesc.children.map((child, childIndex) => (
                        <p key={childIndex} className="lead">{child.text}</p>
                      ))}
                    </div>
                  ))}
                  </p>

                  <div className="spacer-half"></div>
                  {items?.button?.map((btn, btnIndex) => (
                    <a key={btnIndex} className="btn-main" href={btn.link || "#"}>{btn.name}</a>
                  ))}

                </div>
              </div>
            </div>
          </div>
        </section>
      ))}

      {/* Feature section -2 */}
      {homeFeature?.sectionleveltwo?.map(sectionRow => (
        <section id="section-steps" style={{ paddingTop: '80px' }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 text-center">
                {/* <h5 className="s2">Easy Steps</h5> */}
                <h2 style={{ color: '#23539A' }}>{sectionRow.title}</h2>
                <p className="lead">{sectionRow.subtitle}</p>
                <div className="spacer-20"></div>
              </div>

              <div className="col-md-12  fadeInUp">
                <div className="container-timeline">
                  <ul> {sectionRow?.rowheader?.map((rows) => (
                    <li>
                      <h4>{rows.title} </h4>
                      <p>{rows.subtitle}</p>
                    </li>
                  ))}
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </section>
      ))}

      {/* Feature section -3 */}

      {homeFeature?.sectionlevelthree?.map((items, index) => (
        <section key={index} style={{ backgroundColor: items.background === 'Blue' ? '#DBEDF7' : '#FFFFFF', paddingTop: '80px' }} >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 offset-lg-3 text-center">
                {/* <h5 className="s2"> Security</h5> */}
                <h2 style={{ color: '#23539A' }}> {items.title} </h2>
                <p className="lead">{items.subtitle}</p>
              </div>
              <div className="col-lg-6 ">
                <img
                  className="img-fluid anim-up-down"
                  src={`${BASE_URL}${items.image.data[0].attributes.formats.medium.url}`}
                  alt={items.image.data[0].attributes.name}
                  style={{ width: '85%' }}
                />
              </div>
              <div className="col-lg-6 " data-wow-delay=".3s">
                <div className="p-sm-30 pb-sm-0 mb-sm-0">
                  <h2 style={{ color: '#23539A' }}> {items?.description?.map((desc, descIndex) => (
                    <span key={descIndex}>
                      {desc.children.map((child, childIndex) => (
                        <p key={childIndex} ><ReactMarkdown>{child.text}</ReactMarkdown></p>
                      ))}
                    </span>
                  ))}
                  </h2>

                  <p>{items?.subdescription?.map((subDesc, subDescIndex) => (
                    <div key={subDescIndex}>
                      {subDesc.children.map((child, childIndex) => (
                        <p key={childIndex} className="lead"><ReactMarkdown>{child.text}</ReactMarkdown></p>
                      ))}
                    </div>
                  ))}
                  </p>

                  <div className="spacer-half"></div>
                  {items?.button?.map((btn, btnIndex) => (
                    <a key={btnIndex} className="btn-main" href={btn.link || "#"}>{btn.name}</a>
                  ))}

                </div>
              </div>
            </div>
          </div>
        </section>
      ))}




    </div>
  )
}
