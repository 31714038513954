import React, { useState } from 'react';
import subheaderImage from '../assets/images/background/subheader.jpg';
import axios from 'axios';
import './Register.css';
import './UserProfile.css';
import allowedDomains from '../../src/assets/domain_json/domain.json';
import { Link } from 'react-router-dom';

export default function Register() {

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    repassword: '',
  });
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const validate = () => {
    let errors = {};
    if (!formData.firstname) {
      errors.firstname = 'First name is required';
    }

    if (!formData.lastname) {
      errors.lastname = 'Last name is required';
    }

    if (!formData.email) {
      errors.email = 'Email is required';

    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email address is invalid';
    } else {
      const emailParts = formData.email.split('@');
      if (emailParts.length !== 2 || !allowedDomains.includes(emailParts[1].toLowerCase())) {
        errors.email= <p>
          {`The domain you entered "${emailParts[1].toLowerCase()}" is not recognized as an academic domain. If you are from an academic institution in Belgium, please use your professional email address. If you need assistance, please contact us through the `}
          <Link to="https://www.meteo.be/en/about-rmi/contact/contact-rmii/contact-form" target='_blank'>contact page</Link>.
        </p>
      }

    }


    if (!formData.password) {
      errors.password = 'Password is required';
    } else if (formData.password.length < 8) {
      errors.password = 'Password must be at least 8 characters long.';
    } else if (!/[A-Z]/.test(formData.password)) {
      errors.password = 'Password must contain at least one uppercase letter.';
    } else if (!/[a-z]/.test(formData.password)) {
      errors.password = 'Password must contain at least one lowercase letter.';
    }

    if (!formData.repassword) {
      errors.repassword = 'Please confirm your password';
    } else if (formData.password !== formData.repassword) {
      errors.repassword = 'Passwords do not match';
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleEmailChange = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, email: value });

    // Extract domain from email
    const emailParts = value.split('@');
    if (emailParts.length !== 2) {
      setErrors({ ...errors, email: 'Invalid email format' });
      return;
    }

    const domain = emailParts[1].toLowerCase();

    // Check if domain is in the allowed list
    if (allowedDomains.includes(domain)) {
      setErrors({ ...errors, email: '' });
    } else {

      setErrors({
        ...errors,
        email: (
          <p>
            {`The domain you entered "${domain}" is not recognized as an academic domain. If you are from an academic institution, please use your professional email address. If you need assistance, please contact us through the `}
            <Link to="https://www.meteo.be/en/about-rmi/contact/contact-rmi/contact-form" target='_blank'>contact page</Link>.
          </p>
        )
      });
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const closeModal = () => {
    setShowModal(false);
    setIsVisible(false);
  };


  const openPopup = () => {
    toggleModal();
    setIsVisible(!isVisible);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();



    // Handle form submission logic here
    const validationErrors = validate();

    // const emailParts = formData.email.split('@');
    // if (emailParts.length !== 2 || !allowedDomains.includes(emailParts[1].toLowerCase())) {
    //   setErrors({ ...errors, email: 'The domain is not recognized' });
    //   return;
    // }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {

      setErrors({});

      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            // Add other headers as needed
          },
        };

        const regObj = {
          email: formData.email,
          firstname: formData.firstname,
          lastname: formData.lastname,
          password: formData.password,
        };

        // Send data to backend API
        const BASE_URL = process.env.REACT_APP_PROFILE_API_URL;
        const response = await axios.post(`${BASE_URL}/api/v1/register`, regObj, config);
        if (response.data.statusCode === 200) {
          // Clear form and any previous error messages
          setFormData({
            firstname: '',
            lastname: '',
            email: '',
            // phone: '',
            password: '',
            repassword: '',
          });
          setErrorMessage('');
          // setMessage(response?.data?.message)
          openPopup()
          // triggerMessage();
        }
        // Optionally handle response from backend
      } catch (error) {
        console.log(error)
        setErrorMessage(error?.response?.data?.message)
        triggerMessage();
        // Handle error, show error message, etc.
      }

    }
  };

  const triggerMessage = () => {
    // Clear the message after 5 seconds (5000 milliseconds)
    setTimeout(() => {
      setMessage('');
      setErrorMessage('')
    }, 9000); // Adjust the time as per your requirement (5000 ms = 5 seconds)
  };


  const errorStyle = {
    color: 'red',
    fontSize: '0.875em',
    marginTop: '0.25em',
  };


  return (
    <div>
      <section id="subheader" className="jarallax" style={{ backgroundImage: `url(${subheaderImage})` }}>
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1> <i class="fa fa-fw" aria-hidden="true" title="Copy to use vcard-o"></i> Registration</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section aria-label="section" style={{ paddingTop: '20px' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <h3>Don't have an account? Register now.</h3>
              <p>RMI provides a variety of climate data for academic users</p>
              <div className="spacer-10"></div>
              <form name="contactForm" id="contact_form" className="form-border" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="field-set">
                      <label>First Name:</label>
                      <input
                        type="text"
                        name="firstname"
                        id="name"
                        className="form-control"
                        value={formData.firstname}
                        onChange={handleChange}
                      />
                      {errors.firstname && <p style={errorStyle}>{errors.firstname}</p>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="field-set">
                      <label>Last Name:</label>
                      <input
                        type="text"
                        name="lastname"
                        id="lastname"
                        className="form-control"
                        value={formData.lastname}
                        onChange={handleChange}
                      />
                      {errors.lastname && <p style={errorStyle}>{errors.lastname}</p>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="field-set">
                      <label>Email:</label>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        className="form-control"
                        value={formData.email}
                        onChange={handleEmailChange}
                      />
                      {errors.email && (
                        <div style={errorStyle}>{errors.email}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="field-set">
                      <label>Password:</label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className="form-control"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      {errors.password && <p style={errorStyle}>{errors.password}</p>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="field-set">
                      <label>Re-enter Password:</label>
                      <input
                        type="password"
                        name="repassword"
                        id="repassword"
                        className="form-control"
                        value={formData.repassword}
                        onChange={handleChange}
                      />
                      {errors.repassword && <p style={errorStyle}>{errors.repassword}</p>}

                    </div>
                  </div>
                  <div className="col-md-12">
                    <div id="submit" className="pull-left">
                      <input type="submit" id="send_message" value="Register Now" className="btn-main color-2" />
                    </div>
                    <div className="clearfix"></div>
                    {message && (
                      <div style={{ margin: '20px', padding: '15px', color: '#155724', bordercolor: '#c3e6cb', fontWeight: 'bold', backgroundColor: '#d4edda', }}>
                        {message}
                      </div>
                    )}
                    {errorMessage && (
                      <div style={{ margin: '20px', padding: '15px', color: '#721c24', bordercolor: '#f5c6cb', fontWeight: 'bold', backgroundColor: '#f8d7da', }}>
                        {errorMessage}
                      </div>
                    )}

                  </div>
                </div>
              </form>

            </div>
          </div>
        </div >
      </section >

      <div style={{ display: isVisible ? 'block' : 'none' }}>
        <div className={`modal fade ${showModal ? 'show' : ''}`} id="scrollableModal" data-bs-keyboard="false" aria-hidden={!showModal} aria-labelledby="scrollableModalLabel"  >
          <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content" style={{ width: '100%', padding: '0px' }}>
              <div className="modal-header">
                <h4 className="modal-title" id="scrollableModalLabel" style={{ color: '#204E96' }}>You have signed up successfully!</h4>
                <button type="button" className="btn-close" onClick={closeModal} aria-label="Close" style={{ margin: '10px' }}></button>
              </div>
              <div className="modal-body" >
                <p>Please click the link in the activation email to activate your account.</p>


              </div>
              <div className="modal-footer" style={{ display: 'flex' }}>
                <button type="button" className="btn-main" onClick={closeModal} aria-label="Close" style={{ width: '45%', fontWeight: '600' }}>Close</button>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div>
  )
}
