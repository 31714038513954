import React from 'react'
import { useMenuLists } from "../hooks/userFetch";

export default function Contact() {
    const { faqSection } = useMenuLists();
    const { heroSection } = faqSection || {};
    const title = heroSection?.title || '';
    const subtitle = heroSection?.subtitle || '';
    const backgroundImageUrl = heroSection?.image?.data?.attributes?.url || '';
    const BASE_URL = process.env.REACT_APP_CMS_API_URL;

    // const BASE_URL = `${apiUrl}:1337`;
    return (
        <div>
            <section id="subheader" className="jarallax" style={{ backgroundImage: `url(${BASE_URL}${backgroundImageUrl})` }}>
                <div className="center-y relative text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h1>Contact Us</h1>

                                {/* <p className="lead">{subtitle}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section aria-label="section" style={{paddingTop:'20px'}}>
                <div class="container">
                    <div className="row g-custom-x">
                        <div className="col-lg-8 mb-sm-30">
                            <div className="de-map-wrapper">
                                <h4>Location</h4>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2519.5282430976034!2d4.367641716083845!3d50.79803596659568!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3c3aafca8de5f%3A0x54114d028cd0baff!2sRoyal%20Meteorological%20Institute%20of%20Belgium!5e0!3m2!1sen!2sus!4v1686079123456!5m2!1sen!2sus" width="600" height="450" style={{border:'0px'}} allowfullscreen="" loading="lazy"></iframe>
                            </div>
                            <div className="spacer-30"></div>
                            <h5>Do you have any question? Send Email to : support@opendatameteo.com</h5>
                        </div>
                        
                        <div className="col-lg-4">

                            <div className="padding40 box-rounded mb30" data-bgcolor="#F2F6FE">
                                <h4>Royal Meteorological Institute of Belgium</h4>
                                <address className="s1">
                                    <span><i className="id-color fa fa-map-marker fa-lg"></i>3 Avenue Circulaire, B-1180 Brussels</span>
                                    <span><i className="id-color fa fa-phone fa-lg"></i>+1 223344455</span>
                                    <span><i className="id-color fa fa-envelope-o fa-lg"></i><a href="mailto:contact@example.com">support@opendatameteo.com</a></span>
                                    <span><i className="id-color fa fa-map-marker fa-lg"></i><a href="#">Uccle, Brussels, Belgium.</a></span>
                                </address>
                            </div>


                      

                        </div>

                    </div>
                </div>

            </section>
        </div>
    )
}
