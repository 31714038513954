import React from 'react'
import '../assets/css/style.css';
export default function MainMenu(props) {
    const { menuLists } = props

    if (!menuLists || !menuLists.attributes || !menuLists.attributes.MainMenuItems) {
        return null; // or return a loading indicator, e.g., <p>Loading...</p>
    }

    const renderMenuItem = (item) => {
        if (item.__component === 'menu.menu-link') {
            return (
                <li className="menu-item" key={item.id}>
                    <a href={item.url} target={item.target}>{item.title}</a>
                </li>
            );
        } else if (item.__component === 'menu.link') {
            return (
                <li className="menu-item" key={item.id}>
                    <a href={item.url} target={item.target}>{item.name}</a>
                </li>
            );
        } else if (item.__component === 'menu.dropdown') {
            return (
                <li key={item.id}>
                    <a className="menu-item" href={item.url}> {item.title}</a>
                    <span aria-hidden="true" class="arrow_carrot-down"></span>
                    <ul>
                        {item?.sub_menus?.data?.map((section) => (
                            section?.attributes?.link?.map((link) => (
                                <li key={link.id} >
                                    <a class="menu-item" href={link.pageUrl}>{link.pageName}</a>
                                </li>
                            ))
                        ))}
                    </ul>
                </li>
            );
        }
        return null;
    };


    return (
        <div>
            <ul id="mainmenu" >
                {menuLists.attributes.MainMenuItems.map((item) => renderMenuItem(item))}
            </ul>
        </div>
    )
}
