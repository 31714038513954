import React, { useState, useEffect } from 'react';
import { useMenuLists } from "../hooks/userFetch";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getUserDetailsFromToken } from '../utils/jwtUtils';


export default function Download() {
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [beginDate, setBeginDate] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');

  const { downloadSection } = useMenuLists();
  const BASE_URL_BG = process.env.REACT_APP_CMS_API_URL;

  const { heading, description, backgroundImage, subHeading } = downloadSection || {};
  const backgroundImageUrl = backgroundImage?.data?.attributes?.url || '';
  const descriptionText = description?.[0]?.children?.[0]?.text || '';
  const [selectedProduct, setSelectedProduct] = useState('');

  const [layerVisible, setLayerVisible] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState('');
  const [downloadSectionData, setDownloadSectionData] = useState('');

  const [layerOptions, setLayerOptions] = useState([]);

  const [typeValue, setTypesValue] = useState(null);
  const [formatOptions, setFormatOptions] = useState(null);

  const [token, setToken] = useState('');
  const [aggrement, setAggrement] = useState(false);
  useEffect(() => {
    // Retrieve the token from localStorage
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
    const details = getUserDetailsFromToken(storedToken);
    setAggrement(details?.isDomain)

  }, []);


  const [progress, setProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadedData, setDownloadedData] = useState(null);
  
  const streamZipFile = async () => {
    setIsDownloading(true);
    try {
      const BASE_URL = process.env.REACT_APP_PROFILE_API_URL;
      const authToken = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/api/v1/download-files`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authToken}` // Set the authorization header with the token
          }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const contentLength = response.headers.get('Content-Length');
      let receivedLength = 0;
      const chunks = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        chunks.push(value);
        receivedLength += value.length;

        setProgress(Math.round((receivedLength / (contentLength+1)) * 1000));
        //console.info("setProgress:", Math.round((receivedLength / (contentLength+1)) * 1000));
      }

      const blob = new Blob(chunks);
      const url = URL.createObjectURL(blob);
      setDownloadedData(url);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `climateGrid.tar.gz`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

    } catch (error) {
      console.error("Erreur while streaming tar.gz file:", error);
    } finally {
      setIsDownloading(false);
    }
  }
  
  const handleClimateData = async () => {
    
    var urlClim ="";
    if(downloadSectionData == 'monthly-1')
      urlClim = `/../resources/climate/long_series/hist_temp_series_2024_01.zip`;
    if(downloadSectionData == 'monthly-2')
      urlClim = `/../resources/climate/long_series/temp_ho_monthly_series_1954-2015.zip`;
    if(downloadSectionData == 'monthly-3')
      urlClim = `/../resources/climate/long_series/precip_ho_monthly_series_until_2015.zip`;

    try {
      const BASE_URL = process.env.REACT_APP_PROFILE_API_URL;
      const response = await fetch(`${BASE_URL}${urlClim}`, {
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const contentLength = response.headers.get('Content-Length');
      let receivedLength = 0;
      const chunks = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        chunks.push(value);
        receivedLength += value.length;

        setProgress(Math.round((receivedLength / (contentLength+1)) * 1000));
        //console.info("setProgress:", Math.round((receivedLength / (contentLength+1)) * 1000));
      }

      const blob = new Blob(chunks);
      const url = URL.createObjectURL(blob);
      setDownloadedData(url);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `download.zip`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Erreur while streaming tar.gz file:", error);
    } finally {
      setIsDownloading(false);
    }

  }

  var url = "https://opendata.meteo.be";

  const [format, setFormat] = useState('');
  const [boundingBoxData, setBoundingBoxData] = useState(null);
  const [coordinates, setCoordinates] = useState({
    west: '',
    east: '',
    south: '',
    north: ''
  });

  var type = null; // 'raster', 'vector'
  var time = null;
  var timeType = null;
  var boundingBox = null;
  function getMetadataLink(workspace) {
    const links = { "alaro": "https:\/\/opendata.meteo.be\/geonetwork\/srv\/eng\/catalog.search#\/metadata\/RMI_DATASET_ALARO", "appobs": "https:\/\/opendata.meteo.be\/geonetwork\/srv\/eng\/catalog.search#\/metadata\/RMI_DATASET_APPOBS", "lidar": "https:\/\/opendata.meteo.be\/geonetwork\/srv\/eng\/catalog.search#\/metadata\/RMI_DATASET_LIDAR", "extreme_precipitation": "https:\/\/opendata.meteo.be\/geonetwork\/srv\/eng\/catalog.search#\/metadata\/RMI_DATASET_EXTREME_PRECIPITATION", "climate_statistics": "https:\/\/opendata.meteo.be\/geonetwork\/srv\/eng\/catalog.search#\/metadata\/RMI_DATASET_CLIMATE_STATISTICS", "aws": "https:\/\/opendata.meteo.be\/geonetwork\/srv\/eng\/catalog.search#\/metadata\/RMI_DATASET_AWS_10MIN", "synop": "https:\/\/opendata.meteo.be\/geonetwork\/srv\/eng\/catalog.search#\/metadata\/RMI_DATASET_SYNOP", "climateData": "https:\/\/opendata.meteo.be\/geonetwork\/srv\/eng\/catalog.search#\/metadata\/RMI_DATASET_GRIDDEDOBS"};
    return links[workspace];
  }

  const updateProduct = (event) => {
    const product = event.target.value;

    setSelectedProduct(product);

    if (product !== 'longSeriesUccle') {
      setDownloadSectionData('');
    }

    if (product === 'extreme_precipitation' || product === 'climate_statistics') {
      const locations = [
        'https://www.meteo.be/fr/climat/climat-de-la-belgique/climat-dans-votre-commune',
        'https://www.meteo.be/nl/klimaat/klimaat-van-belgie/klimaat-in-uw-gemeente',
      ];

      // Redirect to a random location
      window.location.href = locations[Math.floor(Math.random() * 2)];
      return;
    }

    cleanDiv('dataset-metadata');
    // Clear the dataset-metadata div
    const metadataElement = document.getElementById('dataset-metadata');
    while (metadataElement.firstChild) {
      metadataElement.removeChild(metadataElement.firstChild);
    }

    // Create and append metadata link
    const link = getMetadataLink(product);
    const metadata = document.createElement('a');
    metadata.setAttribute('href', link);
    metadata.setAttribute('target', '_blank');
    metadata.setAttribute('class', 'external');
    metadata.style.paddingRight = '20px';
    metadata.innerHTML = 'Dataset metadata';
    metadataElement.appendChild(metadata);

    fetch(`${url}/ajax/getLayerList.php?product=${product}`)
      .then(response => response.text())
      .then(data => {
        // Process the response XML
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, 'text/xml');
        const layers = Array.from(xmlDoc.getElementsByTagName('layer')).map(layer => {
          return { name: layer.textContent.trim() };
        });
        setLayerOptions(layers)
        setLayerList(xmlDoc);
      });
  };

  function setLayerList(xml) {
    cleanTime();
    type = xml?.getElementsByTagName('type')[0]?.firstChild?.nodeValue;
    setTypesValue(type)
  }



  const [workspace, setWorkspace] = useState(null);
  const updateLayer = (select) => {
    const selectedLayer = select.options[select.selectedIndex].value;
    setSelectedLayer(selectedLayer)
    // setLayer(selectedLayer);

    // Make AJAX request to get format list
    fetch(`${url}/ajax/getFormatList.php?workspace=${selectedProduct}&layer=${selectedLayer}&type=${typeValue}`)
      .then(response => response.text())
      .then(xml => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, 'application/xml');

        // Convert XML to JSON
        const formats = Array.from(xmlDoc.getElementsByTagName('format')).map(format => ({
          id: format.getElementsByTagName('id')[0].textContent,
          name: format.getElementsByTagName('name')[0].textContent
        }));
        setFormatOptions(formats);
      })
      .catch(error => console.error('Error fetching format list:', error));
  };


  const updateFormat = async (select) => {
    const format = select.options[select.selectedIndex].value;

    setFormat(format);
    try {
      const response1 = await fetch(`${url}/ajax/getBoundingBox.php?workspace=${selectedProduct}&layer=${selectedLayer}&type=${typeValue}`);
      if (response1.ok) {
        const responseXML = await response1.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(responseXML, 'application/xml');
        const west = xmlDoc.getElementsByTagName('west')[0].textContent.trim();
        const east = xmlDoc.getElementsByTagName('east')[0].textContent.trim();
        const south = xmlDoc.getElementsByTagName('south')[0].textContent.trim();
        const north = xmlDoc.getElementsByTagName('north')[0].textContent.trim();
        setBoundingBoxData(xmlDoc);
        setBoundingBox(xmlDoc);
        setCoordinates({
          south,
          west,
          north,
          east
        });
        const bounds = [
          [south, west],
          [north, east]
        ];
        // Assuming `selectedZone` and `mapBB` are defined somewhere in your component
        // selectedZone.setBounds(bounds);
      } else {
        console.error('Failed to fetch bounding box');
      }
    } catch (error) {
      console.error('Error fetching bounding box:', error);
    }
    // Fetch the time info
    try {
      const response2 = await fetch(`${url}/ajax/getTimeInfo.php?workspace=${selectedProduct}&layer=${selectedLayer}&type=${typeValue}&format=${format}`);
      if (response2.ok) {
        const responseXML = await response2.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(responseXML, 'application/xml');
        setTimeInfo(xmlDoc);
      } else {
        console.error('Failed to fetch time info');
      }
    } catch (error) {
      console.error('Error fetching time info:', error);
    }


    try {
      const response3 = await fetch(`${url}/ajax/getCustomDimensions.php?workspace=${selectedProduct}&layer=${selectedLayer}&type=${typeValue}`);
      if (response3.ok) {
        const responseXML = await response3.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(responseXML, 'application/xml');
      } else {
        console.error('Failed to fetch custom dimensions');
      }
    } catch (error) {
      console.error('Error fetching custom dimensions:', error);
    }

    // Make mandatory parameters and submit button available
    // mapBB.invalidateSize();
  };


  function setBoundingBox(xml) {
    var bounds = ['north', 'south', 'east', 'west'];

    for (let attr of bounds) {
      if (document.getElementById(attr).value != '') {
        // users already fills the fields, do not overwrite it
        return;
      }
    }


    for (let attr of bounds) {
      document.getElementById(attr).value = xml.getElementsByTagName(attr)[0].firstChild.nodeValue;
    }
  }


  function setTimeInfo(xml) {
  }

  const makeVisible = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.display = 'flex';
    }
  };

  const makeHidden = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.display = 'none';
    }
  };

  const cleanTime = () => {
    cleanDiv('time');
    // setTimeType(null);
  };

  const cleanDiv = (divTag) => {
    const divElement = document.getElementById(divTag);
    if (divElement) {
      while (divElement.firstChild) {
        divElement.removeChild(divElement.firstChild);
      }
    }
  };

  const updatelongSeriesUccle = (event) => {
    setDownloadSectionData(event)
  }

  const handleDownload = () => {
    boundingBox = getBbox();
    // time = getTime();
    // dimensions = '1234';


    var downloadUrl = url + '/download.php?' +
      'workspace=' + selectedProduct +
      '&type=' + typeValue +
      '&layer=' + selectedLayer +
      '&format=' + format
      ;


    if (boundingBox != null) {
      downloadUrl += '&bbox=' + boundingBox.south + ',' + boundingBox.west + ',' + boundingBox.north + ',' + boundingBox.east;
    }



    // if (time != null) {
    //   downloadUrl += '&time=' + time.from + '/' + time.to;
    // }

    // for (let dim in dimensions) {
    //   if (dimensions.hasOwnProperty(dim)) {
    //     downloadUrl += '&' + dim + '=' + dimensions[dim];
    //   }
    // }

    window.location = downloadUrl;
  }

  // const getTime = () => {
  //   if (timeType === 'interval') {
  //     let from = timeFrom.toISOString();
  //     let to = timeTo.toISOString();
  //     return { from: from, to: to };
  //   } else if (timeType === 'slice') {
  //     return { from: timeSlice, to: timeSlice };
  //   } else if (timeType === null) {
  //     return null;
  //   }
  // };

  function getBbox() {
    var bbox = {};
    var keys = ['north', 'south', 'west', 'east'];

    for (let k of keys) {
      bbox[k] = document.getElementById(k).value;
    }

    for (let k of keys) {
      if (bbox[k] == null) {
        return null;
      }
    }

    return bbox;
  }

  const navigate = useNavigate();
  const handleClimateDataRegistering = () => {
    if (token !== '') {
      navigate('/register'); // Replace '/register' with your desired path
    }
  }

  return (
    <div>
      <section id="subheader" className="jarallax" style={{ backgroundImage: `url(${BASE_URL_BG}${backgroundImageUrl})` }}>
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>{heading}</h1>
                <p className="lead">{subHeading}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section-hero" aria-label="section" style={{ padding: '20px 0 0px 0px' }}>
        <div className="container">
          {/* <div className="row">
            <div className="col-lg-12">
              <h3>{heading}</h3>
              <p>{descriptionText}</p>
            </div>
          </div> */}

          <div id="comment-form-wrapper">
            <div className="comment_form_holder">
              <div id="container" >
                <div class="wrapper">
                  <div id="main" role="main" class="clearfix sub" >
                    <div id="content" class="clearfix" style={{ paddingTop: '20px' }}>
                      <h3>Download</h3>
                      <p>You can download our data with Web Coverage Service (WCS) or Web Feature Service (WFS). Some datasets are not yet available in these services, and old data may be omitted.</p>
                      <div class="container-fluid">
                        <div id="product" class="row">
                          <div class="col-3">
                            <b>Product</b> :
                          </div>
                          <div className="col-6">
                            <select id="product_select" onChange={updateProduct}>
                              <option value="" hidden disabled selected>Select a product ...</option>
                              <option value="aws">Automatic weather station (AWS)</option>
                              <option value="climate_statistics">Climate statistics</option>
                              <option value="extreme_precipitation">Extreme precipitations</option>
                              <option value="lidar">Networks of automatic lidar-ceilometer systems</option>
                              <option value="synop">Synoptic observations</option>
                              <option value="appobs">User reports from the RMI smartphone app</option>
                              <option value="alaro">Weather Model Alaro</option>
                              <option value="climateData">Daily gridded climate data - only for academic users</option>
                              <option value="longSeriesUccle">Homogenized monthly climate series</option>
                            </select>
                          </div>
                          <div id="dataset-metadata" style={{ display: "contents" }}></div>
                        </div>
                        {selectedProduct === 'longSeriesUccle' ?
                          <div id="layer" class="row" >
                            <div class="col-3">
                              <b>Long Series</b> :
                            </div>
                            <div className="col-6">
                              <select onChange={(e) => updatelongSeriesUccle(e.target.value)} >
                                <option >Select a layer ...</option>
                                <option value="monthly-1">16 homogenized monthly temperature series (pre-1928 to 2023)</option>
                                <option value="monthly-2">45 homogenized monthly temperature series (1954 to 2015)</option>
                                <option value="monthly-3">110 homogenized monthly precipitation series (pre-1955 to 2015)</option>
                              </select>
                            </div>
                          </div> : ''}

                        {downloadSectionData === 'monthly-1' || downloadSectionData === 'monthly-2' || downloadSectionData === 'monthly-3' ?
                          <div id="layer" class="row">
                            <div class="col-3" style={{ paddingTop: '50px' }}>
                              <button class="btn btn-primary" onClick={handleClimateData} disabled={isDownloading}>{isDownloading ? "Downloading..." : "Download"}</button>
                              {isDownloading && <progress value={progress} max="100">{progress}%</progress>}
                                {downloadedData && (
                                  <div>
                                    <p>Downloaded ! <a href={downloadedData} download="download.zip">file link</a></p>
                                  </div>
                                )}
                            </div>
                          </div>
                          : ''}

                        {selectedProduct === 'climateData' ? (
                          token !== '' && token !== null && aggrement ? (
                            <div id="layer" className="row" style={{ display: 'flex' }}>
                              <div className="col-6" style={{ paddingTop: '50px' }}>
                                <button className="col-3 btn btn-primary" onClick={streamZipFile} disabled={isDownloading}>{isDownloading ? "Downloading..." : "Download"}</button>
				{!isDownloading && <div>File size: ~500MB</div>}
                                {isDownloading && <progress value={progress} max="100">{progress}%</progress>}
                                {downloadedData && (
                                  <div>
                                    <p>Downloaded ! <a href={downloadedData} download="climate.tar.gz">file link</a></p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div id="layer" className="row" style={{ display: 'flex' }}>
                              <div className="col-3" style={{ paddingTop: '50px' }}>
                                <button className="btn btn-primary" onClick={handleClimateDataRegistering}>Download the data after registering.</button>
                              </div>
                            </div>
                          )
                        ) : (
                          ""
                        )}

                        {selectedProduct !== 'climateData' && selectedProduct !== 'longSeriesUccle' ?
                          <>
                            <div id="layer" class="row" style={{ display: selectedProduct ? 'flex' : 'none' }}>
                              <div class="col-3">
                                <b>Layer</b> :
                              </div>
                              <div className="col-6">
                                <select onChange={(e) => updateLayer(e.target)} >
                                  <option >Select a layer ...</option>
                                  {layerOptions?.map(option => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div id="format" class="row" style={{ display: selectedLayer ? 'flex' : 'none' }}>
                              <div class="col-3">
                                <b>Format</b> :
                              </div>
                              <div class="col-6">
                                <select onChange={(e) => updateFormat(e.target)}>
                                  <option>Select a format Options ...</option>
                                  {formatOptions?.map(option => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                  ))}
                                </select>
                              </div>
                            </div>


                            <div id="bbox" class="row" style={{ display: typeValue ? 'flex' : 'none' }}>
                              <div class="row pt-4">
                                <div class="col-3">
                                  <h4>Bounding Box</h4>
                                </div>
                                <div class="col-8">
                                  <div style={{ fontSize: "small" }}>Select the geographic area for which you want to download the data. The default area covers all the data available.
                                    <br />
                                    You can directly enter the desired coordinates.<br />
                                    Or you can edit the area on the map by hitting the edit button <div class="leaflet-draw-toolbar leaflet-bar leaflet-draw-toolbar-top" style={{ display: "inline-block", verticalAlign: "middle" }}><a class="leaflet-draw-edit-edit" href="javacript:void(0)" title="Edit layers"><span class="sr-only">Edit layers</span></a></div> at the top right, and move the white squares. When you are done, hit "Save"
                                  </div>
                                  <div class="row" style={{ color: "grey" }}>Coordinate Reference System : EPSG:4236</div>
                                  <div class="row">

                                    <div class="col-2">West :</div><div class="col-4"><input type="text" value={coordinates.west} style={{ height: "35px" }} id="west" onchange="updateBBox();" /></div>
                                    <div class="col-2">East :</div><div class="col-4"><input type="text" value={coordinates.east} style={{ height: "35px" }} id="east" onChange="updateBBox();" /></div>
                                  </div>
                                  <div class="row">
                                    <div class="col-2">South :</div><div class="col-4"><input type="text" value={coordinates.south} style={{ height: "35px" }} id="south" onchange="updateBBox();" /></div>
                                    <div class="col-2">North :</div><div class="col-4"><input type="text" value={coordinates.north} style={{ height: "35px" }} id="north" onchange="updateBBox();" /></div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style={{ display: selectedProduct ? 'flex' : 'none' }}>
                              <div class="col-3" style={{ paddingTop: '50px' }}>
                                <button class="btn btn-primary" onClick={handleDownload} >Download add</button>
                              </div>
                            </div>
                          </>
                          : ""}

                        <div id="time" class="row pt-4">
                        </div>

                        <div id="custom_dimensions" >
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>
      </section >
    </div >
  )
}
