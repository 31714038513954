import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getUserDetailsFromToken } from '../utils/jwtUtils';
import './UserProfile.css';
import subheaderImage from '../assets/images/background/subheader.jpg';
import { Link } from 'react-router-dom';
import { DOWNLOAD_CONTENT } from '../constants/download';

export default function UserProfile() {
    const [profile, setProfile] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [acknowledgment, setAcknowledgment] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [files, setFiles] = useState([]);
    const BASE_URL = process.env.REACT_APP_PROFILE_API_URL;

    useEffect(() => {

        const fetchProfile = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${BASE_URL}/api/v1/userProfile`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setProfile(response.data);
                if (token) {
                    const details = getUserDetailsFromToken(token);
                    setUserDetails(details);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching profile:', error);
                setLoading(false);
            }
        };
        fetchProfile();

        const fetchFiles = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/v1/files`); // Adjust URL as per your server
                setFiles(response.data);
            } catch (error) {
                console.error('Error fetching files', error);
            }
        };

        fetchFiles();


    }, []);

    const stripExtension = (fileName) => {
        return fileName.replace(/\.[^/.]+$/, "");
    };

    const downloadFile = async (fileName) => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v1/download-file/${fileName}`, {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}.zip`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error(`Error downloading file ${fileName}`, error);
        }
    };


    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleAcknowledgment = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await axios.post(`${BASE_URL}/api/v1/acknowledgment`, userDetails, config);

            if (response.data.status === "success") {
                closeModal();
                localStorage.setItem('token', response.data.token);
                setAcknowledgment(true);
                const details = getUserDetailsFromToken(response.data.token);
                setUserDetails(details);
            }

        } catch (error) {
            console.error('Error logging in:', error);
            //setErrorMessage(error.response.data.message);
        }

    };

    const handleCheckboxChange = (event) => {
        console.log(event.target.checked)
        setIsChecked(!isChecked);
    };

    if (loading) {
        return (
            <section id="subheader" className="jarallax">
                <div className="center-y relative text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h1>Loading...</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }


    const handleAcknowledgedView = () => {
        toggleModal()
    }
    return (
        <div>

            {profile ? (
                <>
                    <section id="subheader" className="jarallax" style={{ paddingBottom: '0px' }}>
                        <div className="center-y relative text-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 text-center" style={{ paddingTop: '50px' }}>
                                        <div>
                                            <h3 style={{ color: "#0056b3" }}>{DOWNLOAD_CONTENT.WELCOME_TEXT}, {userDetails.name}</h3>
                                        </div>
                                        <p className="lead" style={{ fontSize: '16px', margin: "30px 80px 0px 80px", textAlign: 'justify' }}>{DOWNLOAD_CONTENT.DECRIPTION_TEXT}</p>
                                        <br></br>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section aria-label="section" style={{ paddingTop: '0px', paddingBottom: '0px', margin: '0px 150px 0px 190px' }}>
                        <div class="container">
                            <div className="row g-custom-x">
                                <div className="col-lg-12 mb-sm-30">
                                    {(acknowledgment || userDetails?.isDomain) ? (
                                        <div>
                                            <h4 style={{ color: "#0056b3" }}>{DOWNLOAD_CONTENT.COMPLETED_TEXT}</h4>
                                            <p className="lead" style={{ fontSize: '16px' }}>
                                                {DOWNLOAD_CONTENT.COMPLETED_DESCRIPTION} <a href='#' onClick={handleAcknowledgedView} style={{ textDecoration: "underline" }}>View the aggrement</a>
                                            </p>
                                        </div>
                                    ) : <div>
                                        <h4 style={{ color: "#0056b3" }}>{DOWNLOAD_CONTENT.CONFIRMATION_TEXT}</h4>
                                        <p className="lead" style={{ fontSize: '16px' }}>{DOWNLOAD_CONTENT.AGREEMENT_TEXT}</p>
                                    </div>}

                                    <button type="button"
                                        disabled={acknowledgment || userDetails?.isDomain}
                                        className={`btn-main ${acknowledgment || userDetails?.isDomain ? 'disabled-button' : ''}`}
                                        onClick={toggleModal}
                                        style={{ width: "auto", zIndex: '999', position: 'relative' }}
                                    >
                                        {DOWNLOAD_CONTENT.CLICK_AGGR}
                                    </button>

                                    {acknowledgment || userDetails?.isDomain ?
                                        <div style={{ fontSize: "14px", paddingLeft: "20px", zIndex: '9999', height: 'auto' }}>
                                            <h5 style={{ paddingTop: "15px" }}><Link to="/download">Click here</Link>{DOWNLOAD_CONTENT.DOWNLOAD_TEXT}</h5>
					    <p dangerouslySetInnerHTML={{ __html: DOWNLOAD_CONTENT.DOWNLOAD_DESC }} />

                                        </div>

                                        : ''}
                                    <div className="spacer-50"></div>
                                </div>
                            </div>
                        </div>


                        <div className={`modal fade ${showModal ? 'show' : ''}`} id="scrollableModal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="scrollableModalLabel" style={{ zIndex: '999', display: showModal ? 'flex' : 'none' }} >
                            <div className="modal-dialog modal-dialog-scrollable">
                                <div className="modal-content" style={{ width: '100%', padding: '0px' }}>
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="scrollableModalLabel">{DOWNLOAD_CONTENT.MODEL_AGGREMENT_TITLE}</h5>
                                        <button type="button" className="btn-close" onClick={closeModal} aria-label="Close" style={{ margin: '10px' }}></button>
                                    </div>
                                    <div className="modal-body" >
                                    <p dangerouslySetInnerHTML={{ __html: DOWNLOAD_CONTENT.MODEL_AGGREMENT_DESC }} />
                                    </div>
                                    <div>
                                        <label style={{ display: 'flex' }}>

                                            {acknowledgment || userDetails?.isDomain ?
                                                <input
                                                    type="checkbox"
                                                    disabled={acknowledgment || userDetails?.isDomain}
                                                    checked={true}
                                                    onChange={handleCheckboxChange}
                                                    style={{ appearance: 'auto', width: '18px', margin: '0px 17px' }}
                                                />
                                                : <input
                                                    type="checkbox"
                                                    disabled={acknowledgment || userDetails?.isDomain}
                                                    checked={isChecked}
                                                    onChange={handleCheckboxChange}
                                                    style={{ appearance: 'auto', width: '18px', margin: '0px 17px' }}
                                                />}
                                            I agree to the terms and conditions {isChecked}
                                        </label>
                                    </div>

                                    <div className="modal-footer" style={{ display: 'flex' }}>
                                        <button type="button" className="btn-main" onClick={closeModal} aria-label="Close" style={{ width: '45%', fontWeight: '600' }}>Close</button>
                                        <button type="button" className={`btn-main ${!isChecked ? 'disabled-button' : ''}`} onClick={handleAcknowledgment} style={{ width: '45%', fontWeight: '600' }} disabled={!isChecked} >{acknowledgment || userDetails?.isDomain ? 'Submitted' : 'Submit'} </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            ) : (
                <section id="subheader" className="jarallax" style={{ backgroundImage: `url(${subheaderImage})` }}>
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1 style={{ fontFamily: '"Plus Jakarta Sans", Helvetica, Arial, sans-serif' }}>404 <br></br>Page not found</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>)
            }
        </div >
    )
}
