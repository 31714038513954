import { useState, useEffect } from "react";
import axios from "axios";

export const useMenuLists = () => {
    const [menuLists, setMenuLists] = useState([]);
    const [heroContent, setHeroContent] = useState([]);
    const [homeFeature, setHomeFeature] = useState([]);
    const [footerSection, setFooterSection] = useState([]);
    const [downloadSection, setDownloadSection] = useState([]);
    const [faqSection, setFaqSection] = useState([]);
    const apiUrl = process.env.REACT_APP_CMS_API_URL;

    const axiosClient = axios.create({
        baseURL: `${apiUrl}/api/`,
    });

    const fetchMenuList = async () => {
        try {
            const {
                data: { data },
            } = await axiosClient.get("main-menu?populate[0]=MainMenuItems&populate[1]=MainMenuItems.sub_menus&populate[2]=MainMenuItems.sub_menus.link");
            setMenuLists(data);

        } catch (error) {
            console.log({ error });
        }
    };

    const fetchHomeHeroContent = async () => {
        try {
            const {
                data: { data },
            } = await axiosClient.get("home-hero?populate=*");
            setHeroContent(data.attributes);
        } catch (error) {
            console.log({ error });
        }
    };

    const fetchHomeFeatureContent = async () => {
        try {
            const {
                data: { data },
            } = await axiosClient.get("homepages?populate[0]=sectionlevels.image&populate[1]=sectionlevels.button&populate[2]=sectionleveltwo.rowheader&populate[3]=sectionlevelthree.image&populate[4]=sectionlevelthree.button");
            setHomeFeature(data[0].attributes);
        } catch (error) {
            console.log({ error });
        }
    };

    const fetchFooterSection = async () => {
        try {
            const {
                data: { data },
            } = await axiosClient.get("footer?populate[0]=logo&populate[1]=footerMenu.footer&populate[2]=footerSocialMedia.medialist.language&populate[3]=footerLanguage.language&populate[4]=SubFooterSection.SubFooterList");
            setFooterSection(data.attributes);
        } catch (error) {
            console.log({ error });
        }
    };

    const fetchDownloadSection = async () => {
        try {
            const {
                data: { data },
            } = await axiosClient.get("downloads/1?populate[0]=backgroundImage");
            setDownloadSection(data.attributes);
        } catch (error) {
            console.log({ error });
        }
    };

    const fetchFaqSection = async () => {
        try {
            const {
                data: { data },
            } = await axiosClient.get("faqpages/1?populate[0]=heroSection.image&populate[1]=faqContent.faqdynamic_qa");
            setFaqSection(data.attributes);
        } catch (error) {
            console.log({ error });
        }
    };

    useEffect(() => {
        fetchMenuList();
        fetchHomeHeroContent();
        fetchHomeFeatureContent();
        fetchFooterSection();
        fetchDownloadSection();
        fetchFaqSection()
    }, []);

    return { menuLists, heroContent, homeFeature, footerSection, downloadSection, faqSection }; //categories,
};