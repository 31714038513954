import React from 'react'
import { useState } from "react";
import { Link } from 'react-router-dom';
import { useMenuLists } from "../hooks/userFetch";
import ReactMarkdown from 'react-markdown';

export default function Faq(props) {

    const [activeTab, setActiveTab] = useState(null);
    const { faqSection } = useMenuLists();

    const { heroSection, faqContent } = faqSection || {};
    const title = heroSection?.title || '';
    const subtitle = heroSection?.subtitle || '';
    const backgroundImageUrl = heroSection?.image?.data?.attributes?.url || '';

    const BASE_URL = process.env.REACT_APP_CMS_API_URL;

    const handleClick = (tab) => {
        setActiveTab(activeTab === tab ? null : tab);
    };

    return (
        <div>
            {faqSection ?
                <section id="subheader" className="jarallax" style={{ backgroundImage: `url(${BASE_URL}${backgroundImageUrl})` }}>
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1>{title}</h1>

                                    <p className="lead">{subtitle}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                : ''}
            {faqContent ?
                <section id="section-features" style={{ paddingTop: '25px' }}>
                    <div className="container">
                        <div className="row g-5">
                         
                            <div className="col-md-12">
                                <div className="box-highlight">
                                    <div className="heading">
                                        <h4>A frequently asked questions (FAQ)</h4>
                                    </div>
                                    <div className="content">
                                        <div className="accordion">
                                            {faqContent && faqContent.map((section) =>
                                                section.faqdynamic_qa.map((item) => (
                                                    <div key={item.id} className="accordion-section">
                                                        <div
                                                            className={`accordion-section-title ${activeTab === item.id ? 'active' : ''}`}
                                                            onClick={() => handleClick(item.id)}
                                                        >
                                                            {item.question}
                                                        </div>
                                                        <div
                                                            className="accordion-section-content"
                                                            style={{ display: activeTab === item.id ? 'block' : 'none' }}
                                                        >
                                                            <ReactMarkdown>{item.answer}</ReactMarkdown>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> : ''}
        </div >

    )
}
