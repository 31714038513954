import React from 'react'
import { useMenuLists } from "../hooks/userFetch";
import ReactMarkdown from 'react-markdown';
import subheaderImage from '../assets/images/background/subheader.jpg';
import { useLocation } from 'react-router-dom';
function Termsandconditions() {
    const { footerSection } = useMenuLists();
    const location = useLocation();
    const currentRoute = location.pathname.replace("/", "");
    const getHeadingAndContent = (currentRoute) => {
        if (!footerSection.SubFooterSection || footerSection.SubFooterSection.length === 0) {
            return null;
        }

        const subFooterList = footerSection.SubFooterSection[0].SubFooterList;
        for (let item of subFooterList) {
            if (item.link === currentRoute) {
                return {
                    heading: item.heading,
                    content: item.content
                };
            }
        }
        return null;
    };

    const result = getHeadingAndContent(currentRoute);


    return (
        <div>
            <div>
                <section id="subheader" className="jarallax" style={{ backgroundImage: `url(${subheaderImage})` }}>
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    {result ? (
                                        <>
                                            <h1>{result.heading}</h1>
                                        </>
                                    ) : ''}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section aria-label="section" style={{ paddingTop: '50px' }}>
                    <div class="container">
                        <div className="row g-custom-x">
                            <div className="col-lg-12">
                                {result ? (
                                    <>
                                        <p>   <ReactMarkdown>{result.content}</ReactMarkdown></p>
                                    </>
                                ) : ''}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Termsandconditions
