export const DOWNLOAD_CONTENT = {
    WELCOME_TEXT: "Welcome to your user dashboard",
    DECRIPTION_TEXT: "You can download our data with Web Coverage Service (WCS) or Web Feature Service (WFS). Some datasets are not yet available in these services, and old data may be omitted.",
    COMPLETED_TEXT: "Completed your academic agreement:",
    COMPLETED_DESCRIPTION: "The confirmation academic agreement has already been acknowledged by yourself.",
    CONFIRMATION_TEXT: "Confirmation of academic agreement:",
    AGREEMENT_TEXT: "This agreement ensures that users acknowledge and adhere to specified guidelines, legal requirements, or usage policies related to the document's content or distribution. This agreement helps protect the document's integrity and ensures compliance with applicable laws and regulations.",
    CLICK_AGGR: "Click here for academic agreement",
    DOWNLOAD_TEXT: " for academic download",
    DOWNLOAD_DESC: `Gridded observational data provides an estimate of the spatial distribution of a meteorological variable based on observations. While instrumental measurements are taken at irregularly distributed stations, gridded data represents the meteorological variable on a predefined regular grid. Gridded observational data is of strong benefit in disciplines applying distributed quantitative models to examine the influence of weather and climate. Gridded data are also very convenient to provide estimations for any specific location of interest for the user.<br/>
<br/>
Gridded observational data covering Belgium at a spatial resolution of 5 km (= 1360 pixels) are available for several variables: precipitation accumulation, air temperature, sunshine duration, horizontal solar radiation, relative humidity, atmospheric pressure and reference evapotranspiration ET0 (calculated variable).<br/>
<br/>
The gridded database starts in 1961 for precipitations, air temperature, atmospheric pressure and relative humidity.<br/>
For sunshine duration, solar radiation and the estimated reference evapotranspiration, the data starts in 1984 with the availability of Meteosat satellite data.<br/>
<br/>
The gridded data is updated each day with the available observations of the previous day. All grids are then updated for archiving as soon as the data quality control is completed.
`,
    MODEL_AGGREMENT_TITLE: "Agreement Terms and Conditions",
    MODEL_AGGREMENT_DESC: `All content published on the open data platform of the Royal Meteorological Institute of Belgium (RMI), are protected by law, remain the intellectual property of the RMI and may be re-used, distributed or presented publicly, midst the user explicitly refers to the RMI as the official source of the raw data.<br/>
 All open data of the RMI that are defined as high-value datasets (<a href="https://eur-lex.europa.eu/eli/reg_impl/2023/138/oj" target="_new">HVD</a>) may be re-used under the Creative Commons licence conditions <a href="https://creativecommons.org/licenses/by/4.0/" target="_new">CC BY 4.0</a> provided that the source is acknowledged.<br/>
The registration to the RMI open data platform allows the academic users to have freely access to climate gridded data, which is interpolated data on a grid of 5x5km covering the Belgian territory, or aggregated data per Belgian community. The climate gridded data are not part of the HVD datasets and therefore may not be re-used, distributed or presented publicly, in any (machine-) readable formats.<br/>
<br/>
When acknowledging the RMI as, the following rules have to be observed:<br/>
<ul>
    <li>The obligation to indicate the source details shall apply to any data and other services of the RMI that are used without alteration. Source details must also be included even if extracts of the data/services are used or if the data format is changed.</li>
    <li>In the event of more advanced alterations or any processing, layout redesigns or other adaptation of the data/services, the RMI at least expects to be mentioned in a central list of references or in the imprint/publishing details.</li>
    <li>The RMI will not be held liable for any damages resulting from the use of its data, should they be the original data or should they have been processed and modified by the user.  </li>
    <li>The RMI will not be held liable for any Internet outages or access interruptions due to technical problems. </li>
    <li>The RMI issues official weather warnings. If any of information (region, type of weather event, color code, start and end of the warning period) is altered, you must indicate how you modified the material and retain an indication of previous modifications. </li>
    <li>Source details may include links to web pages of the RMI.</li>
    <li>Acknowledgement of the RMI as source can be made either graphically or in text form. The source details must be placed immediately next to the information.</li>
</ul>
<br/>
By acknowledging those conditions, the user commits him-herself to follow the rules.<br/>
`
}
